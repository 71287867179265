<template>
	<div>
		<Navbar />

	    <!-- BEGIN: Main Menu-->
	    <Menu />
	    <!-- END: Main Menu-->

	    <!-- BEGIN: Content-->
	    <div class="app-content content">
	        <div class="content-wrapper">
	        	<router-view/>
	        </div>
	    </div>
	    <!-- END: Content-->

	    <div class="sidenav-overlay"></div>
	    <div class="drag-target"></div>

	    <!-- BEGIN: Footer-->
	    <Footer />
	    <!-- END: Footer-->
	</div>
</template>

<script>
import Navbar from '@/layouts/components/Navbar'
import Menu from '@/layouts/components/Menu'
import Footer from '@/layouts/components/Footer'
export default {
	components: {
		Navbar,
		Menu,
		Footer
	}
}
</script>